import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { PageTypes } from './content/contentApi';

interface PageContextData {
  page: PageTypes;
  setPage: React.Dispatch<PageTypes>;
}

const defaultData: PageContextData = {
  page: {},
  setPage: () => {
    return;
  },
};
const PageContext = createContext<PageContextData>(defaultData);

interface PageContextProviderProps {
  initialPage?: PageTypes;
}

export const PageContextProvider: FC<PageContextProviderProps> = ({ initialPage = defaultData.page, children }) => {
  const [page, setPage] = useState<PageTypes>(initialPage);
  useEffect(() => {
    setPage(initialPage);
  }, [initialPage, setPage]);

  return <PageContext.Provider value={{ page, setPage }}>{children}</PageContext.Provider>;
};

export const usePageContext = (): PageContextData => useContext(PageContext);
