import { Default404Section } from 'jexity/packages/feature/404/Default404Section';
import React, { FC } from 'react';
import { CtaSection } from './content/cars-page/cars';
import { defaultContentPageSerializer } from './contentPageSerializer';
import { usePageContext } from './PageContextProvider';

export interface PageContentRendererProps {
  type: string;
  ctaSection?: CtaSection | null;
}

const renderContent = (node) => {
  const contentKey = `content-key-${node?._id}`;
  const Component = defaultContentPageSerializer[node._type];

  return Component ? <Component key={contentKey} content={node} /> : null;
};

const PageContentLoader: FC<PageContentRendererProps> = ({ type }) => {
  const { page } = usePageContext();

  if (!type) {
    // Occurs in preview mode. If the slug of the page was changes and the page was published. Then the old slug does
    // not exits any more
    return <Default404Section />;
  }

  return page ? renderContent(page) : null;
};

export default PageContentLoader;
