import React, { FC } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { Box } from '@chakra-ui/react';

import { NextSeo } from 'next-seo';
import { buildOpenGraphImages, getCanonicalUrl } from 'jexity/packages/feature/common/seo';
import { isAsset } from 'sanity-picture-asset';
import { DefaultPreloaderPage } from 'jexity/packages/feature/preloader';
import { useBaseTheme } from 'jexity/packages/baseTheme/baseTheme';
import { keywordsToString } from 'jexity/packages/utils/stringHelpers';
import PageContentLoader from './pageContentLoader';
import { NavigationLinks } from '../layout/Layout';
import { PreviewMode, SiteSettingsContextProvider } from 'jexity/packages/feature/common';
import { PageContextProvider } from './PageContextProvider';
import { CompanyInfo, SiteSettings } from 'jexity/packages/feature/common/siteSettingsApi';
import { Default404Page } from 'jexity/packages/feature/404';
import { PageTypes } from './content/contentApi';
import { DEFAULT_LANGUAGE } from '../../theme/languages';
import { TranslationContextProvider } from '../../theme/translations/TranslationContext';
import { TranslationsContent } from '../../theme/translations/api';
import { CtaSection } from './content/cars-page/cars';

const SimplePagePreviewHandler = dynamic(() => import('jexity/packages/feature/page/SimplePagePreviewHandler'));

export interface ContentPageProps<T = PageTypes> extends PreviewMode {
  page: T | null;
  ctaSection?: CtaSection | null;
  siteSettings: SiteSettings;
  companyInfo: CompanyInfo;
  navigationLinks: NavigationLinks;
  translations: TranslationsContent;
}

export const ContentPage: FC<ContentPageProps> = ({
  page,
  ctaSection,
  siteSettings,
  companyInfo,
  preview = false,
  navigationLinks,
  translations,
}) => {
  const router = useRouter();
  const { isFallback, asPath, locale = DEFAULT_LANGUAGE } = router;
  const { layoutComponent: Layout } = useBaseTheme();
  const slug = page?.slug?.current;
  const { title } = siteSettings;

  if (isFallback) {
    // is this case the server data is not ready, so we can't use any fields from the component props
    return <DefaultPreloaderPage />;
  }

  if (page === null) {
    return <Default404Page siteSettings={siteSettings} companyInfo={companyInfo} navigationLinks={navigationLinks} />;
  }

  return (
    <SiteSettingsContextProvider initialSiteSettings={siteSettings} initialCompanyInfo={companyInfo}>
      <PageContextProvider initialPage={page}>
        <TranslationContextProvider initialTranslations={translations}>
          <Layout {...navigationLinks}>
            <NextSeo
              title={title}
              description={siteSettings.description}
              canonical={getCanonicalUrl(asPath)}
              openGraph={{
                url: getCanonicalUrl(asPath),
                locale: locale,
                title: title,
                description: siteSettings.description,
                images: [buildOpenGraphImages(isAsset(siteSettings.ogImage) ? siteSettings.ogImage : undefined, title)],
                site_name: companyInfo.name ?? '',
              }}
              additionalMetaTags={[
                {
                  name: 'keywords',
                  content: keywordsToString(siteSettings.keywords),
                },
              ]}
            />

            {preview && slug && <SimplePagePreviewHandler type={page._type ?? ''} slug={slug} />}
            <Box as="main" minH="100%">
              <PageContentLoader type={page._type ?? ''} ctaSection={ctaSection} />
            </Box>
          </Layout>
        </TranslationContextProvider>
      </PageContextProvider>
    </SiteSettingsContextProvider>
  );
};
